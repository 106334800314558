





















































import Vue from "vue";

export default Vue.extend({
  name: "Equipe",
  props: {
    username: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    mail: {
      type: String,
      default: ""
    },
    twitter: {
      type: String,
      default: ""
    },
    github: {
      type: String,
      default: ""
    },
    site: {
      type: String,
      default: ""
    },
    discord: {
      type: String,
      default: ""
    }
  },
});
