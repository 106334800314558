







































import Vue from "vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Equipe from "@/components/Equipe.vue";

export default Vue.extend({
  components: { Breadcrumb, Equipe },
});
